import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { AppBar, Toolbar, Typography, Button, IconButton, Container } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import ListIcon from '@material-ui/icons/List';
import GroupIcon from '@material-ui/icons/Group';
import BarChartIcon from '@material-ui/icons/BarChart';

import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { Global } from '../models/Global'
import AGTheme, { ThemeColors } from '../Theme'
import { RestComponent, HomepageContext, PopupMenu, Permissions } from 'react-frontend-utils'
import { User } from 'react-frontend-utils'
import { WelcomePage } from '../pages/WelcomePage'
import JobsListPage from '../pages/JobsListPage'
import CommunityPage from '../pages/CommunityPage'
import StatisticsPage from '../pages/StatisticsPage'
import ExpiredPage from '../pages/ExpiredPage'
import { logo } from '../utils/Image'


//This is the main page for the Web Portal. It contains the AppBar and MainMenu and handles login/logout.
//Below the AppBar, the HomePage selects one Page out of the available Pages to display, based on what
//is selected by the user. The WecomePage is the initial page shown before login.


//These are the available Pages that can be selected from the MainMenu that will be displayed below the AppBar
export const Pages = {
    WELCOME: "WELCOME",
    EXPIRED: "EXPIRED",
    JOBS: "JOBS",
    COMMUNITIES: "COMMUNITIES",
    STATISTICS: "STATISTICS",
    HELP: "HELP",
    
    //Returns true if the type is one of the MainMenuSwitch types, false otherwise
    isOneOf: (type) => {
        switch (type) {
            case Pages.WELCOME:
            case Pages.EXPIRED:
            case Pages.JOBS:
            case Pages.COMMUNITIES:
            case Pages.STATISTICS:
            case Pages.HELP:
                return true;
            default:
                return false;
        }
    }
};
Object.freeze(Pages);



const AppBarIconButton = withStyles((theme) => ({
    root: {
        '&:hover': {backgroundColor: ThemeColors.lightTooltipHover}
    }
}))(IconButton);

const AppBarButton = withStyles((theme) => ({
    root: {
        '&:hover': {backgroundColor: ThemeColors.lightTooltipHover}
    }
}))(Button);


const poolPassIcon = (<PeopleIcon fontSize="small"/>);
const applicationsIcon = (<AssignmentIcon fontSize="small"/>);
const accountPortalIcon = (<AccountBalanceIcon fontSize="small"/>);
const helpIcon = (<HelpOutlineIcon fontSize="small"/>);

const gotoPoolPassPortal = () => {
    window.location.href ="https://portal.pool-pass.com";
};

const gotoApplicationsPortal = () => {
    window.location.href = "https://applications.accessgrantedsystems.net";        
};

const gotoAccountsPortal = () => {
    window.location.href = "https://accounts.accessgrantedsystems.net";        
};

const gotoHelp= () => {
    window.location.href = "https://support.accessgrantedsystems.com/portal";        
};

class Home extends RestComponent {
  
  
    styles = {
        appTitle: {
           marginLeft: 10,
           textShadow: "2px 2px #333333",
           fontWeight: "bold",
           fontSize: "200%",
           flexGrow: 1   //fill all space to push other elements to the right edge
        }
    }
  
 
    constructor(props) {
        super(props);
        this.state.isAuthenticated = false;       //true if the current user has been authenticated via OAuth
        this.state.serverError = null;
        this.state.isMobile = false;
        this.state.helpPage = null;               //selected help page
        this.state.currentUser = null;
        this.state.helpHeight = window.innerHeight;
        this.state.selectedPage = Pages.WELCOME;  //default

        this.state.jobID = props.id ? encodeURIComponent(props.id) : null;  //user asks for a specific job, encode for URL safe
    }


    /**
     * When the Home page loads, see if the user is currently authenticated
     */
    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        this._fetchCurrentUser();
    }
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isMobile: window.innerWidth < 600, helpHeight: window.innerHeight - 200 });  //custom, split between bootstrap and mui
    }


    
    _fetchCurrentUser = () => {
        this.secureJSONFetch("/auth/currentUser", {}, this._checkUserCallback); 
    }


    /**
     * Callback to be executed from fetching the current user
     * @param {Object} response null if there is no current authenticated user, or a JSON object describing the user
     */
    _checkUserCallback = (response) => {
        if (response === null) {
            this.setState(({isAuthenticated: false, currentUser: null}));  //user is not authenticated
            Global.user = null;
            console.log("No user is currently logged in");
     
        } 
        else {
            const user = new User(response);  //create the global user
            this.setState({currentUser: user, isAuthenticated: true});
            
            console.log("User: " + user.name()  + " (" + user.id + "/" + user.role + ") logged in, available databases are: " + user.databases.toString());
            Global.user = user;
            
            this._gotoJobsPage(this.state.jobID); //when authenticated, jump to jobs page
        }
    }
        

    /**
     * User action by pressing the LOGOUT button
     */
       logout() {
        super.logout( () => {
            this.setState({currentUser: null, isAuthenticated: false});
            Global.user = null;
        });
    }

    sessionExpired = () => {
        this.setState({selectedPage: Pages.EXPIRED, currentUser: null, isAuthenticated: false, applicationID: null});
    }


    //Goto Jobs page with the specific job id, if no id, just go to the list
    _gotoJobsPage = (id) => {
        if (id)
            this.props.history.push("/passportal?id=" + id);
        else
            this.props.history.push("/passportal");

        this.setState({selectedPage: Pages.JOBS, jobID: id});    
        console.log("Goto Jobs Page, Query ID: " + id);
    }
    
    _gotoCommunitiesPage = () => {
        this.setState({selectedPage: Pages.COMMUNITIES});            
    }

    _gotoStatisticsPage = () => {
        this.setState({selectedPage: Pages.STATISTICS});
    }


    /**
     * Callback from the MainMenu component when a user selects an item from the menu 
     * @param {Pages item} selectedItem one of the Pages items
     */
    mainMenuCallback = (selectedItem) => {
        
        if (Pages.isOneOf(selectedItem)) {
            this.setState({selectedPage: selectedItem}); 
            console.log("Switching page to " + selectedItem);
        }
        else {
            console.log("Invalid Page passed to mainMenuCallback");
        }
             
    }
    
    _mainMenu = () => {
  
    
        const items = (() => {

            let menuItems = [];

            //Goto the Jobs page without an ID
            menuItems.push({label: "Job Queue", 
                            icon: <ListIcon fontSize="small"/>, 
                            isSelected: (this.state.selectedPage === Pages.JOBS), 
                            selectCallback: () => this._gotoJobsPage(null)
                            });


            menuItems.push({label: "Statistics",
                            icon: <BarChartIcon fontSize="small"/>,
                            isSelected: (this.state.selectedPage === Pages.STATISTICS), 
                            selectCallback: this._gotoStatisticsPage
            });
     
                       
            
            if (this.state.currentUser.hasPermissionTo(Permissions.MANAGE_PASSES)) {

                menuItems.push({label: "Communities",
                               icon: <GroupIcon fontSize="small"/>,
                               isSelected: (this.state.selectedPage === Pages.COMMUNITIES), 
                               selectCallback: this._gotoCommunitiesPage
                               });
                               
            }

            menuItems.push(null);

            menuItems.push({label: "Manage Memberships", 
                icon: poolPassIcon, 
                isSelected: false, 
                selectCallback: gotoPoolPassPortal
            });

            menuItems.push({label: "Manage Applications", 
                    icon: applicationsIcon, 
                    isSelected: false, 
                    selectCallback: gotoApplicationsPortal
                    });
                    

            if (this.state.currentUser.hasPermissionTo(Permissions.VIEW_ACCOUNT_PORTAL)) {

                menuItems.push({label: "Manage Account", 
                                icon: accountPortalIcon, 
                                isSelected: false, 
                                selectCallback: gotoAccountsPortal}
                                );        
                            
            }

            menuItems.push({label: "Help", 
                    icon: helpIcon, 
                    isSelected: false, 
                    selectCallback: gotoHelp}
                    );

            return menuItems;

        })();

        return (
            <PopupMenu menuIcon={<MenuIcon/>} menuItems={items} lighterHover={true}/> 
        );   
    }


    _showHealth = () => {
        let port = (window.location.port ? ':' + window.location.port : '');
        if (port === ":3000") {
            port = ":8080";  //switch to 8080 if using yarn development port
        }
        window.location.href = "//" + window.location.hostname + port + "/monitoring"; 
    }
    
    _showLogs = () => {
        let port = (window.location.port ? ':' + window.location.port : '');
        if (port === ":3000") {
            port = ":8080";  //switch to 8080 if using yarn development port
        }
        window.location.href = "//" + window.location.hostname + port + "/actuator/logfile"; 
    }

    render() {

        //Set the login button based on the state of the authentication
        const loginLogoutButton = this.state.isAuthenticated ?
            <AppBarButton color="inherit" onClick={() => { this.logout(); }}>LOGOUT</AppBarButton>
            :
            <AppBarButton color="inherit" onClick={() => { this.login(); }}>LOGIN</AppBarButton>;


        //Only shows the main menu when authenticated 
        const showMainMenu = this.state.isAuthenticated;

        let limitMaxWidth = false;
        
       
        //Selects the current page to view
        const viewingPage = (() => {
                       
            switch (this.state.selectedPage) {

                case Pages.WELCOME:   
                    return <WelcomePage loginFunc={(provider) => this.login(provider)}/>;
              
                case Pages.EXPIRED:   
                    return <ExpiredPage/>;
                    
                case Pages.JOBS:
                    return <JobsListPage/>;

                case Pages.STATISTICS:
                    return <StatisticsPage/>;
    
                case Pages.COMMUNITIES:
                    return <CommunityPage/>;
     
                /*
                case Pages.HELP:
                    return <HelpPage helpHeight={this.state.helpHeight} visiblePage={this.state.helpPage} selectPageCallback={(page) => {this.setState({helpPage: page});}} />;
                */

                default:
                    return <div>Page Not Found</div>;

            }
        })();
        
        const serverErrorMessage = this.state.serverError ? <Typography variant="h5">Server Error: {this.state.serverError}</Typography> : null;
      
        const gutterMargin = this.state.isMobile ? 8 : 20;
        
               
        return (
            <HomepageContext.Provider value={{sessionExpiredCallback: this.sessionExpired}}>
    
                <ThemeProvider theme={AGTheme}>
                    <Fragment>

                        {this.getConfirmAlertComponent()  /*inject an alert component*/}  

                        <AppBar position="static" style={{marginBottom: 12, backgroundColor: ThemeColors.appBarBackground}}>
                            <div style={{paddingTop: 0, paddingBottom: 4, paddingLeft: gutterMargin, paddingRight: gutterMargin}}>

                                <Toolbar disableGutters={true}>
                                     {logo}
                                     <Typography variant="h5" style={this.styles.appTitle}>Pass Portal</Typography>

                                     {this.state.currentUser && this.state.currentUser.isSuperAdmin() ? 
                                        <div>
                                            <AppBarIconButton edge="end" onClick={this._showLogs} style={{marginRight: 5}} >
                                                <DescriptionOutlinedIcon fontSize="small" style={{color: 'white'}}/>
                                            </AppBarIconButton>
                                            <AppBarIconButton edge="end" onClick={this._showHealth} style={{marginRight: 5}} >
                                                <FavoriteBorderIcon fontSize="small" style={{color: 'white'}}/>
                                            </AppBarIconButton>
                                        </div>
                                        : null
                                     }
                                     
                                      
                                     {loginLogoutButton}
                                     <div style={{padding: 5}}/>
                                     {showMainMenu ? this._mainMenu() : null}


                                </Toolbar>
                                <div style={{display: "flex", flexWrap: "nowrap"}}>
                                    <Typography variant="subtitle1" style={{textAlign: 'right', fontSize: this.state.isMobile ? 13 : 16}}>{this.state.currentUser ? ("User: " + this.state.currentUser.name()) : null}</Typography>
                                </div>
                            </div>
                        </AppBar>


                        {serverErrorMessage}
                        
                        <Container maxWidth={limitMaxWidth ? 'lg' : false} disableGutters={true} style={{paddingLeft: gutterMargin, paddingRight: gutterMargin}}>
                            {viewingPage} 
                        </Container>

                    </Fragment>
                </ThemeProvider>    
            </HomepageContext.Provider>
        );
    }
};

export default withCookies(withRouter(Home));


